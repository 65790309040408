import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";


//const baseUrl = process.env.API_URL ? process.env.API_URL : 'http://localhost:3002';
const baseUrl = process.env.API_URL ? process.env.API_URL : 'https://ssm-bet.com';
const baseUrlMatches = baseUrl + "/api/v1/matches";


export function getMatches(dateParam) {
  return axios.get(baseUrlMatches, {params: {date: dateParam}})
    .then(handleResponse)
    .catch(handleError);
}

export function getMatchesLiveV1() {
  return axios.get(baseUrlMatches + '/live-v1')
    .then(handleResponse)
    .catch(handleError);
}

export function getMatchesLiveV2() {
  return axios.get(baseUrlMatches + '/live-v2')
    .then(handleResponse)
    .catch(handleError);
}

export function getMatchesLiveV3() {
  return axios.get(baseUrlMatches + '/live-v3')
    .then(handleResponse)
    .catch(handleError);
}
