import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";

//const baseUrl = process.env.API_URL ? process.env.API_URL : 'http://localhost:3002';
const baseUrl = process.env.API_URL ? process.env.API_URL : 'https://ssm-bet.com';

const baseUrlAuth = baseUrl + "/api/v1/authentication/";

export function getLogin(credentials) {
    return axios.post(baseUrlAuth + "login", credentials)
      .then(handleResponse)
      .catch(handleError);
}

export function getVerification(tokenParam) {
    return axios.post(baseUrlAuth + "verify", {token: tokenParam})
      .then(handleResponse)
      .catch(handleError);
}

