import React from "react";
import LogoImage from "../img/sslogo.jpeg";

function MainHeader() {

    return (
        <div style={{padding: 0, height: 75, borderBottom: '1px solid #d1a41a', backgroundColor: 'black'}} className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
            <img src={LogoImage} alt="" style={{height: 75, margin: 'auto'}}/>
        </div>
    );

}

export default MainHeader;