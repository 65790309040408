import React from "react";
import ReactHlsPlayer from 'react-hls-player';
import DefaultShield from "../img/shields/default-shield-4.png";

function Player(props) {
    const { url, team_home_name, team_away_name, score, team_id_away, team_id_home } = props.data;
    
    return (
        <article className="panel is-warning" style={{border: '1px solid #28272E'}}>
            <div className="panel-heading" style={{background: '#28272E'}}>
                <div className="columns is-size-4 has-text-white is-flex-mobile is-size-7-mobile">
                    <div className="column text-center">
                        {team_id_home ?
                        <img 
                            style={{margin: 'auto'}}
                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${team_id_home}_small.png&w=48&q=75`}
                            alt={team_home_name}
                            height="32" width="32"
                        /> : 
                            <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                        }
                        <div className="mt-2">
                            {team_home_name}
                        </div>
                    </div>
                    <div className="column text-center is-size-3">
                        {score ? score : 'X'}
                    </div>
                    <div className="column text-center">
                        {team_id_away ?
                        <img 
                            style={{margin: 'auto'}}
                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${team_id_away}_small.png&w=48&q=75`}
                            alt={team_away_name}
                            height="32" width="32"
                        /> : 
                            <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                        }
                        <div className="mt-2">
                            {team_away_name}
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-tabs">
                <a className="is-active">
                    <div className="b-btn-live"><span className="blink-dot dot-live"></span>LIVE</div>
                </a>
            </div>
            <div className="is-active">
                <div>
                    {url ? 
                    <div>
                        <ReactHlsPlayer
                            src={url}
                            autoPlay={true}
                            controls={true}
                            width="90%"
                            style={{margin: 'auto'}}
                            height="auto"
                        />
                    </div>
                    :
                    <div className="label-default default-center-div">Buscando...
                        <progress className="progress is-small is-primary" max="100">15%</progress>
                    </div>
                    }
                </div>
            </div>
        </article>
    )
  }

  export default Player;