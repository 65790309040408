import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

function Navbar(props) {
  const {setOptionNavbar, logout} = props;
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FontAwesomeIcon icon={faBars} onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars-close'>
                <FontAwesomeIcon icon={faClose} />
              </Link>
            </li>
            <li className="nav-text">
              <Link onClick={() => setOptionNavbar('BUSCAR_JOGOS')}>
                <FontAwesomeIcon icon={faSearch} />
                <span>Buscar</span>
              </Link>
            </li>
           {/* <li className="nav-text">
              <Link onClick={() => setOptionNavbar('JOGOS_AOVIVO')}>
                <FontAwesomeIcon icon={faTv} />
                <span>Live</span>
              </Link>
            </li>
            <li className="nav-text">
              <Link onClick={() => setOptionNavbar('ADICIONAR_LIVE')}>
                <FontAwesomeIcon icon={faPlus} />
                <span>Adicionar Live</span>
              </Link>
            </li>
           <li className="nav-text">
              <Link>
                <FontAwesomeIcon icon={faTv} />
                <span>League of legends</span>
              </Link>
            </li>
            <li className="nav-text">
              <Link>
                <FontAwesomeIcon icon={faTv} />
                <span>Counter Strike</span>
              </Link>
            </li>
            <li className="nav-text">
              <Link>
                <FontAwesomeIcon icon={faTv} />
                <span>Valorant</span>
              </Link>
            </li>*/}
            <li className="nav-text">
              <Link onClick={() => setOptionNavbar('SUPORTE')}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span>Suporte</span>
              </Link>
            </li>
            <li className="nav-text">
              <Link onClick={() => logout()}>
                <FontAwesomeIcon icon={faSignOut} />
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
