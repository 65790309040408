
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLogin } from "../api/auth";
import React, { useState } from "react";
import LogoImage from "../img/sslogo.jpeg";
import { useCookies } from 'react-cookie';
import {useNavigate} from 'react-router-dom';

function LoginPage() {
    const year = new Date().getFullYear();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [cookies, setCookie] = useCookies(['user'])
    const navigate = useNavigate();

    const loginValidation = () => {
        if (!username.length) {
            setLoginError("Informe o usuário.");
            return;
        }
        if (!password.length) {
            setLoginError("Informe a senha.");
            return;
        }
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!loginValidation()) {
            return;
        }
        try {
            const result = await getLogin({user: username, password: password});
        
            loginVerification(result);
        } catch (ex) {
            console.log('Error Login ', ex);
            setLoginError("Não foi possível efetuar Login. Verifique se os dados estão corretos e tente novamente.");
        }
    };

    const loginVerification = (data) => {
        if (data.success) {
            setCookie('token',data.token);
            setCookie('response',data);
            navigate("/");
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{width: 300, margin:'auto'}}  data-theme="dark">
                <div className="m-4">
                    <img src={LogoImage} alt="" />
                </div>
                <div className="m-4">
                    <div className="field">
                        <p className="control has-icons-left has-icons-right">
                            <input className="input is-warning is-small" placeholder="Usuário" type="text"
                            value={username} onChange={(e) => setUsername(e.target.value)}/>
                            <span className="icon is-small is-left has-text-warning">
                                <FontAwesomeIcon icon={faUser} />
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input className="input is-warning is-small" type="password" placeholder="Senha" 
                            value={password} onChange={(e) => setPassword(e.target.value)}/>
                            <span className="icon is-small is-left has-text-warning">
                                <FontAwesomeIcon icon={faLock} />
                            </span>
                        </p>
                    </div>
                </div>
                {loginError.length > 0 &&
                <div className="notification is-danger m-4">
                    {loginError}
                </div>}
                <div className="m-4">
                    <button className="button is-small is-fullwidth is-warning ">Entrar</button>
                </div>
                <div className="m-4" style={{padding: 75}}>
                    © {year} SSM Bet
                </div>
            </div>
        </form>
    );
}

export default LoginPage;