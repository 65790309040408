import React, { useEffect, useState } from "react";
import CardMatchInfo from './CardMatchInfo';
import { getMatches } from '../api/matches';
//import { getMatches, getMatchesLiveV1, getMatchesLiveV2, getMatchesLiveV3 } from '../api/matches';
import countryList from '../util/country-codes.json';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Player from './Player';

import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import CardMatchPlayV1 from "./CardMatchPlayV1";
import CardMatchPlayV2 from "./CardMatchPlayV2";
import CardMatchPlayV3 from "./CardMatchPlayV3";

function CardMatchList(props) {
    const { optionNavbar } = props;


    let [leagues, setLeagues] = useState([]);
    let [leaguesFormatted, setLeaguesFormatted] = useState([]);
    let [countries, setCountries] = useState([]);

    let [filteredLeagues, setFilteredLeagues] = useState([]);
    let [display, setDisplay] = useState('BUSCAR_JOGOS');
    let [dateFilter, setDateFilter] = useState(null);

    let [matchesLiveV1, setMatchesLiveV1] = useState([]);
    let [matchesLiveV2, setMatchesLiveV2] = useState([]);
    let [matchesLiveV3, setMatchesLiveV3] = useState([]);

    let [displayMatch, setDisplayMatch] = useState(null);

    let [totalOfMatches, setTotalOfMatches] = useState(0);


    function showMatch(matchData) {
        setDisplayMatch( matchData );
    }
  
    useEffect(() => {
        (async () => {
            if (display === 'JOGOS_AOVIVO') {
                let formattedDate = formatNewDate(new Date());
                const response = await getMatches(formattedDate);
                const leaguesAndMatches = response.payload.leagues;
                setLeagues(leaguesAndMatches);
                
                //const matchesV1 = await getMatchesLiveV1();
                const matchesV1 = [];
                //console.log('matchesV1 >>  ', matchesV1)
                /*
                let soccerMatchesV1 = matchesV1.payload?.data?.filter(p => p.name === 'Football');
                
                let soccerMatchesWithLeaguesV1 = [];
                soccerMatchesV1 && soccerMatchesV1[0]?.childs && soccerMatchesV1[0].childs.forEach(league => {
                    soccerMatchesWithLeaguesV1.push(league.childs.map(m => {
                        return {...m, league_name: league.name};
                    }));
                });
                //console.log('soccerMatchesWithLeaguesV1 ', soccerMatchesWithLeaguesV1.flat());
                soccerMatchesV1 = soccerMatchesWithLeaguesV1.flat()
                setMatchesLiveV1(soccerMatchesV1);
*/
                //const matchesV2 = await getMatchesLiveV2();
                const matchesV2 = [];
/*
                let soccerMatchesV2 = matchesV2.payload.filter(p => p.sport_name === 'Soccer');
                soccerMatchesV2 = soccerMatchesV2[0].data ? soccerMatchesV2[0].data.map(s => {
                    let team_id_home = null;
                    let team_id_away = null;

                    leaguesAndMatches.forEach(l => {
                        l.matches.forEach(m => {
                            if (team_id_home == null) {
                                let id_home = findHomeTeamIdsByName(m, s);
                                if (id_home) {
                                    team_id_home = id_home;
                                }
                            }

                            if (team_id_away == null) {
                                let id_away = findAwayTeamIdsByName(m, s);
                                if (id_away) {
                                    team_id_away = id_away;
                                }
                            }
                            
                        })
                    })
                    return {...s, team_id_home, team_id_away, start_time_brazilian: minusHours(5, new Date(s.start_time))};
                }) : undefined;

                setMatchesLiveV2(soccerMatchesV2);*/

                //const matchesV3 = await getMatchesLiveV3();
                const matchesV3 = [];
                
                //let soccerMatchesV3 = !matchesV3.payload.error ? matchesV3.payload?.filter(p => p.SportID === 'Football')[0].Data : [];

                //setMatchesLiveV3(soccerMatchesV3);
                setMatchesLiveV3([]);
                /*
                let totalListOne = soccerMatchesV1 ? soccerMatchesV1.length : 0;
                let totalListTwo = soccerMatchesV2 ? soccerMatchesV2.length : 0;
                let totalListThree = soccerMatchesV3 ? soccerMatchesV3.length : 0;
                setTotalOfMatches(totalListOne + totalListTwo + totalListThree);*/
            }
        })();
    }, [display]);    

    function findHomeTeamIdsByName(matchData, matchLive) {
        let idfound = null;
        if (matchData.home.name === matchLive.team_one_name) {
            idfound = matchData.home.id;
        }

        if (!idfound) {
            let arrName = matchData.home.name.split(' ');
    
            arrName.forEach(partOfName => {
                if (!idfound && matchLive.team_one_name.includes(partOfName)) {
                    idfound = matchData.home.id;
                }
            });
        }
        return idfound;
    }

    function findAwayTeamIdsByName(matchData, matchLive) {
        let idfound = null;
        if (matchData.away.name === matchLive.team_two_name) {
            idfound = matchData.away.id;
        }
        
        if (!idfound) {
            let arrName = matchData.away.name.split(' ');
            arrName.forEach(partOfName => {
                if (!idfound && matchLive.team_two_name.includes(partOfName)) {
                    idfound = matchData.away.id;
                }
            });
        }
        return idfound;
    }

    useEffect(() => {
        (async () => {
            if (dateFilter) {
                setCountries([]);
                const matches = await getMatches(dateFilter.replaceAll("-", ""));
                setLeagues(matches.payload.leagues);
            }
        })();
    }, [dateFilter])

    useEffect(() => {
        let withFormattedDates = leagues.map(l => {
            const matchesWithTimezone = l.matches.map(m => {
                return {...m, brazilian_timezone: minusHours(5, new Date(m.timeTS))}
            });
            return {...l, matches: matchesWithTimezone};
        })
        updateContent(withFormattedDates);
    }, [leagues]); 

    useEffect(() => {
        if (optionNavbar) {
            setDisplay(optionNavbar);
        }
    }, [optionNavbar]); 

    const formatNewDate = (currentDate) => {
        let month = currentDate.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }

        let day = currentDate.getDate();
        if (day < 10) {
            day = '0' + day;
        }

        return (currentDate.getFullYear() +""+ month +""+  day).toString();
    }

    function minusHours(hours, date) {
        const d = new Date(date.getTime() - (hours * 60 * 60 * 1000))
        let day = d.getDate();
        if (day < 10) {
            day = "0" + day;
        }

        let month = d.getMonth() + 1;
        if (month < 10) {
            month = "0" + month;
        }

        let hour = d.getHours();
        if (hour < 10) {
            hour = "0" + hour;
        }

        let minutes = d.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }

        return day + "/" + month + "/" + d.getFullYear()  + " " + hour + ":" + minutes;
    }

    const updateContent = (withFormattedDates) => {      
        setLeaguesFormatted(withFormattedDates);
        setFilteredLeagues(withFormattedDates);
        
        let countryCodeList = leagues.map(l => l.ccode);
        let cleanDuplicates = [...new Set(countryCodeList)].sort();

        let countryListFound = countryList.filter(c => cleanDuplicates.includes(c.iso3));
        setCountries(countryListFound);
    }

    const filterByCountry = (selectedCountry) => {
        if(selectedCountry !== 'TODOS_PAISES') {
            setFilteredLeagues(leaguesFormatted.filter(l => l.ccode === selectedCountry));
        } else {
            setFilteredLeagues(leaguesFormatted);
        }
    }

    let [displayList, setDisplayList] = useState('LIST_1');

    return (
        <section data-theme="dark">
            {display === 'BUSCAR_JOGOS' ? 
                <div id="buscar_jogos">
                    <div className="m-4">
                        <div className="input-date-picker">
                            <div className="m-2">
                                <label className="label-default">Data</label>
                                <input id="datajogo" className="input input-date-picker" type="date" placeholder="Data do Jogo" onChange={(e) => setDateFilter(e.target.value)}/>
                            </div>
                            <div className="m-2">
                                {countries.length === 0 ?
                                    <div>
                                        Carregando países...
                                        <progress className="progress is-small is-primary" max="100"></progress>
                                    </div>
                                :
                                <div className="mt-5">
                                    <div className="select">
                                        <select onChange={(e) => filterByCountry(e.target.value)}>
                                            <option value="TODOS_PAISES">Todos os países</option>
                                            {countries.map((c) => {
                                                return (
                                                    <option key={c.iso3} value={c.iso3}>{c.nome}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    {filteredLeagues.map((l) => {
                        return (
                            <div key={l.id} >
                                <div className="m-2 title-leagues">
                                    <div className="p-4">
                                        <FontAwesomeIcon icon={faTrophy} />
                                        <span className="pl-2">{l.name} - {l.ccode} </span>
                                    </div>
                                </div>
                                <div className="columns is-desktop m-4 box-matches" >
                                    <CardMatchInfo matches={l.matches}  />
                                </div>
                            </div>
                        )
                    })}
                </div>
            : display === 'JOGOS_AOVIVO' ? 
                <div id="jogos_aovivo">
                    {!displayMatch ?
                        <div className="m-4">
                            <div className="label-default default-center-div">
                                {totalOfMatches ? 
                                    <div>Lives {totalOfMatches}</div>
                                :   
                                <div>
                                    Carregando Lives...
                                    <progress className="progress is-small is-primary" max="100"></progress>
                                </div>
                                }
                            </div>
                            <div className="matches tabs is-toggle is-toggle-rounded is-centered m-4">
                                <ul>
                                    <li className={displayList === 'LIST_1' ? 'is-active' : ''} onClick={() => setDisplayList('LIST_1')}>
                                        <a>
                                            <FontAwesomeIcon icon={faList} className="icon is-small"/>
                                            <span>Lista 1</span>
                                        </a>
                                    </li>
                                    <li className={displayList === 'LIST_2' ? 'is-active' : ''} onClick={() => setDisplayList('LIST_2')}>
                                        <a>
                                            <FontAwesomeIcon icon={faList} className="icon is-small"/>
                                            <span>Lista 2</span>
                                        </a>
                                    </li>
                                    <li className={displayList === 'LIST_3' ? 'is-active' : ''} onClick={() => setDisplayList('LIST_3')}>
                                        <a>
                                            <FontAwesomeIcon icon={faList} className="icon is-small"/>
                                            <span>Lista 3</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="columns is-desktop m-4 box-matches" >
                                { displayList === 'LIST_1' ?
                                  <CardMatchPlayV1 matchesLive={matchesLiveV1} showMatch={showMatch}/>
                                : displayList === 'LIST_2' ?
                                  <CardMatchPlayV2 matchesLive={matchesLiveV2} showMatch={showMatch}/>
                                : <CardMatchPlayV3 matchesLive={matchesLiveV3} showMatch={showMatch}/>}
                            </div>
                        </div>
                    : 
                    <div className="m-4">
                        {displayMatch &&
                        <div className="mt-4 mb-4">
                            <button className="button is-link is-outlined default-button-player" onClick={() => showMatch(null)}>
                                <FontAwesomeIcon icon={faChevronLeft} className="icon is-small"/>
                                <span>Voltar</span>
                            </button>
                        </div>}
                        <Player data={displayMatch}/>
                    </div>}   
                </div> 
            : display === 'ADICIONAR_LIVE' ?
                <div id="adicionar_live" className="m-4">
                    <div className="big-center-div">
                        <FontAwesomeIcon icon={faExclamationTriangle} className="icon is-small mr-2"/>
                        <span>Não é possível adicionar novas lives no momento</span>
                    </div>
                </div> 
            : display === 'SUPORTE' ?
                <div id="suporte" className="m-4">
                    <div className="mid-center-div">
                        <FontAwesomeIcon icon={faEnvelope} className="icon is-small mr-2"/>
                        <span>support@sslivematch.com</span>
                    </div>
                </div> 
            : <></>}
        </section>
    )

}


export default CardMatchList;