import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import DefaultShield from "../img/shields/default-shield-4.png";

function CardMatchPlayV2(props) {
    const {matchesLive, showMatch} = props;
    return (
        matchesLive ? matchesLive.map((match) => {
            return (
                <div data-theme="dark" key={match.match_id} className="column  is-one-third matchCard">
                    <div className="card">
                        <div className="card-content is-size-7">
                            <div className="">
                                <div className="columns m-1 is-flex-mobile">
                                    <div className="b-btn-live"><span className="blink-dot dot-live"></span>LIVE</div>
                                </div>
                                <div className="columns is-flex-mobile m-4">
                                    <div className="is-5 column text-center">
                                        {match.team_id_home ? 
                                        <img 
                                            style={{margin: 'auto'}}
                                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${match.team_id_home}_small.png&w=48&q=75`}
                                            alt={match.team_one_name}
                                            height="32" width="32"
                                        /> : 
                                            <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                                        }
                                        <div className="mt-2">
                                            {match.team_one_name}
                                        </div>
                                    </div>
                                    <div className="column text-center" style={{fontStyle: 'italic', fontWeight: 400}}>
                                        {match.score.replace(':', ' x ')}
                                    </div>
                                    <div className="column is-5 text-center">
                                        {match.team_id_away ?
                                        <img 
                                            style={{margin: 'auto'}}
                                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${match.team_id_away}_small.png&w=48&q=75`}
                                            alt={match.team_two_name}
                                            height="32" width="32"
                                        /> : 
                                            <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                                        }
                                        <div className="mt-2">
                                            {match.team_two_name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content columns ">
                                <div className="column">
                                    <span className="mr-2">
                                        <FontAwesomeIcon icon={faCalendarCheck} />
                                    </span>
                                    <span className="mr-2">
                                        <FontAwesomeIcon icon={faClock} />
                                    </span>
                                    <time dateTime={match.start_time_brazilian}>{match.start_time_brazilian}</time>
                                </div>
                            </div>
                            <div className="content columns is-flex-mobile">
                                <div className="column is-6">
                                    <button className="button is-primary is-inverted is-fullwidth" 
                                    onClick={() => showMatch({ 
                                            url: match.m3u8_source, 
                                            team_home_name: match.team_one_name, 
                                            team_away_name: match.team_two_name,
                                            team_id_away: match.team_id_away,
                                            team_id_home: match.team_id_home,
                                            score: match.score.replace(':', ' x ')
                                        })}>
                                        <FontAwesomeIcon className="mr-2" icon={faPlay} />
                                    </button>
                                </div>
                                <div className="column is-6">
                                    <button className="button is-primary is-inverted is-fullwidth" 
                                        onClick={()=> {
                                            let iframe =`<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src=${match.iframe_source} style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>`;

                                            let win = window.open("","","width=600,height=480,toolbar=no,menubar=no,resizable=yes");
                                            win.document.write(iframe);
                                        }}>
                                        <FontAwesomeIcon className="mr-2" icon={faPlay} />
                                        <FontAwesomeIcon className="mr-2" icon={faWindowMaximize}  />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }) : <div className="label-default mid-center-div">Não há lives disponíveis na lista 2.</div>
    )
}

export default CardMatchPlayV2;