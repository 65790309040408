import React, { useEffect, useState } from "react";
import Player from './components/Player';
import MainHeader from './components/MainHeader';
import Navbar from './components/Navbar';

import CardMatchList from './components/CardMatchList';
import { getVerification } from './api/auth';
import {useNavigate} from 'react-router-dom';
import DisableDevtool from 'disable-devtool';

import { useCookies } from 'react-cookie'

function App() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['user'])

  let [optionNavbar, setOptionNavbar] = useState(null);

  let [verifyResultStatus, setVerifyResultStatus] = useState(null);

  useEffect(() => {
    DisableDevtool();
    (async () => {
      try {
        const verifyResult = await getVerification(cookies.token);
        setVerifyResultStatus(verifyResult.success);
        if (!verifyResult.success) {
          navigate("/login");
        }
      } catch(ex) {
        navigate("/login");
      }
    })();
  }, []);

  const logout = () => {
    removeCookie('token');
    navigate("/login");
  }

  return (
    <div className="App" id="app" data-theme="dark">
      
      <Navbar setOptionNavbar={setOptionNavbar} logout={logout} />
      <MainHeader />
      
      {verifyResultStatus ?
        <CardMatchList optionNavbar={optionNavbar} />
      : <div/>}
      
    </div>
  );
}

export default App;
