import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { faShield } from "@fortawesome/free-solid-svg-icons";

function CardMatchInfo(props) {
    const {matches} = props;
    
    return (
        matches && matches.map((match) => {
            return (
                <div data-theme="dark" key={match.id} className="column is-one-quarter">
                    <div className="card">
                        <div className="card-content is-size-7">
                            <div className="">
                                <div className="columns is-flex-mobile m-4" style={{fontStyle: 'italic'}}>
                                    <div className="text-center is-5 column">
                                        <img 
                                            style={{margin: 'auto'}}
                                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${match.home.id}_small.png&w=48&q=75`}
                                            alt={match.home.name}
                                            height="32" width="32"
                                        />
                                        <div className="mt-2">
                                            {match.home.name}
                                        </div>
                                    </div>
                                    <div className="text-center is-2 column" >x</div>
                                    <div className="text-center is-5 column">
                                        <img 
                                            style={{margin: 'auto'}}
                                            src={`https://www.fotmob.com/_next/image?url=https://images.fotmob.com/image_resources/logo/teamlogo/${match.away.id}_small.png&w=48&q=75`}
                                            alt={match.home.name}
                                            height="32" width="32"
                                        />
                                        <div className="mt-2">
                                            {match.away.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content">
                                <span className="mr-2">
                                    <FontAwesomeIcon icon={faCalendarCheck} />
                                </span>
                                <span className="mr-2">
                                    <FontAwesomeIcon icon={faClock} />
                                </span>
                                <time>{match.brazilian_timezone}</time>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )
}

export default CardMatchInfo;