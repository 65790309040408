import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import DefaultShield from "../img/shields/default-shield-4.png";

function CardMatchPlay(props) {
    const {matchesLive, showMatch} = props;
    return (
        matchesLive && matchesLive.length ? matchesLive.map((match) => {
            return (
                <div data-theme="dark" key={match.league + match.team_one} className="column is-one-third matchCard">
                    <div className="card">
                        <div className="card-content is-size-7">
                            <div className="text-center">
                                <div className="title-leagues">
                                    <FontAwesomeIcon icon={faTrophy} />
                                    <span className="pl-2">{match.league}</span>
                                </div>
                            </div>
                            <div className="">
                                <div className="columns m-1 is-flex-mobile">
                                    <div className="b-btn-live">
                                        <span className="blink-dot dot-live"></span>LIVE
                                    </div>
                                </div>
                                <div className="columns m-4 is-flex-mobile">
                                    <div className="is-5 column text-center">
                                        <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                                        <div className="mt-2">
                                            {match.team_one}
                                        </div>
                                    </div>
                                    <div className="column text-center" style={{fontStyle: 'italic'}}>
                                        x
                                    </div>
                                    <div className="column is-5 text-center">
                                        <img src={DefaultShield} alt="Default Shield" height="32" width="32" style={{margin: 'auto'}}/>
                                        <div className="mt-2">
                                            {match.team_two}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content columns is-flex-mobile">
                                <div className="column is-6">
                                    <button className="button is-primary is-inverted is-fullwidth" 
                                    onClick={() => 
                                    showMatch({ 
                                        url: match.hls_source, 
                                        team_home_name: match.team_one, 
                                        team_away_name: match.team_two,
                                    })}
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faPlay} />
                                    </button>
                                </div>
                                <div className="column is-6">
                                    <button className="button is-primary is-inverted is-fullwidth" 
                                        onClick={()=> {
                                            let iframe =`<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src=${match.iframe_source} style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>`;

                                            let win = window.open("","","width=600,height=480,toolbar=no,menubar=no,resizable=yes");
                                            win.document.write(iframe);
                                        }}>
                                        <FontAwesomeIcon className="mr-2" icon={faPlay} />
                                        <FontAwesomeIcon className="mr-2" icon={faWindowMaximize}  />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }) : <div className="label-default mid-center-div">Não há lives disponíveis na lista 3.</div>
    )
}

export default CardMatchPlay;